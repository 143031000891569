import React from 'react'
import { Row, Col, Form, Modal, Button, Input, Select, Spin, DatePicker } from 'antd'
import { connect } from 'react-redux'
import { getUser } from '../../../../../utilities/token'
import { updateInquiry, loadInquiryById } from '../../actions'
import { loadTenant } from '../../../tenants/actions'
import moment from 'moment'
import { getParamFromUrl } from '../../../../../utilities/helper'

const { Option } = Select;
class Edit extends React.Component {
  formRef = React.createRef();
  constructor() {
    super()
    this.state = {
      base64ImageUrl: '',
      isLoading: false,
      fileImage: undefined,
      fileVideo: undefined,
      fileImageUploaded: false,
      fileVideoUploaded: false,
      fileImageList: [],
      fileVideoList: [],
      // preloaded data only works when redirected from services and if the modal is open
      preloadedData: {}
    }
  }

  componentWillMount() {
    if ((getParamFromUrl('modal') === 'true') && Object.keys(this.props.selectedRow).length === 0) {
      this.setState({ isEditModalOpen: true })
      this.props.onLoadInquiryById(getParamFromUrl('serviceId')).then(res => {
        this.formRef.current.setFieldsValue({
          ...res.payload,
          dateInquiry: moment(res.payload.dateInquiry),
          approvalDate: moment(res.payload.approvalDate).format('LLL'),
          status: this.getStatusLabel(res.payload.status),
        });
        this.setState({ preloadedData: res.payload })
      })
    }
  }

  getFileImage(e) {
    const list = e.map(data => {
      return { name: data.name, url: `images/${data.name}` }
    })
    this.setState({ fileImageList: list })
    this.setState({ fileImage: e })
  }

  getFileVideo(e) {
    const list = e.map(data => {
      return { name: data.name, url: `videos/${data.name}` }
    })
    this.setState({ fileVideoList: list })
    this.setState({ fileVideo: e })
  }

  componentDidMount() {
    this.setState({ base64ImageUrl: '' })
    this.props.onLoadTenant()
  }

  handleUpdate(values) {
    this.setState({ isLoading: true })
    const selectedRowId = (this.props.selectedRow && this.props.selectedRow.id) || this.state.preloadedData.id

    const submitData = {
      "tenantId": this.props.selectedRow.tenantId,
      "title": values.title,
      "description": values.description,
      "dateInquiry": values.dateInquiry,
      "email": values.email,
      "name": values.name,
      "contactNumber": values.contactNumber,
      "remarks": values.remarks,
      "status": this.getStatusEnum(values.status),

      // APPROVED
      "approvalDate": (values.status === 'APPROVED' && (this.props.selectedRow.status !== this.getStatusEnum(values.status))) && moment(new Date()),
      "approvedById": (values.status === 'APPROVED' && (this.props.selectedRow.status !== this.getStatusEnum(values.status))) && getUser().id,

    }
    this.props.onUpdateInquiry({ ...submitData, id: selectedRowId }).then(res => {
      this.setState({ isLoading: false })
    })

  }

  getStatusEnum(status) {
    switch (status) {
      case 'APPROVAL':
        return 1
      case 'APPROVED':
        return 2
      case 'ONGOING':
        return 3
      case 'COMPLETED':
        return 4
      case 'CANCELLED':
        return 5
      case 'REJECTED':
        return 6
      default:
        break;
    }
  }

  getStatusLabel(status) {
    switch (status) {
      case 1:
        return 'APPROVAL'
      case 2:
        return 'APPROVED'
      case 3:
        return 'ONGOING'
      case 4:
        return 'COMPLETED'
      case 5:
        return 'CANCELLED'
      case 6:
        return 'REJECTED'
      default:
        break;
    }
  }

  access(title, role) {
    if (this.props.adminData.roles.length === 0) return false
    const found = this.props.adminData.roles.find(data => data.title === title)
    if (found !== -1) return (found && found.roles[role]) ? true : false
    else return false
  }

  disabledDate(current) {
    return current && current < moment().startOf('day');
  }


  render() {
    const { selectedRow } = this.props
    const isReadOnly = false // Cant edit if the status is for approval = 1
    return (
      <Modal
        title="EDIT LEASING ASSISTANCE"
        centered
        visible={this.props.visible}
        // onOk={() => setVisible(false)}
        onCancel={() => this.props.onCancel()}
        width={1000}
        footer={null}
      >
        <Spin size="large" spinning={this.props.tenantsLoading}>

          <Form
            name="basic"
            ref={this.formRef}
            initialValues={{
              ...this.props.selectedRow,
              dateInquiry: moment(this.props.selectedRow.dateInquiry),
              approvedBy: selectedRow.approver && selectedRow.approver.name || '',
              approvalDate: selectedRow.approvalDate && moment(selectedRow.approvalDate).format('LLL') || '',
              status: this.getStatusLabel(this.props.selectedRow.status),
              contractors: this.props.selectedRow.contractors || []
            }}
            onFinish={(e) => this.handleUpdate(e)}
          // onFinishFailed={onFinishFailed}
          >
            <Row gutter={12}>
              <Col md={12} lg={12} sm={24} xs={24}>
                <Form.Item
                  label="Title"
                  name="title"
                  rules={[{ required: true, message: 'Please input your title!' }]}
                >
                  <Input readOnly={isReadOnly} />
                </Form.Item>

                <Form.Item
                  label="Description"
                  name="description"
                  rules={[{ required: true, message: 'Please input your description!' }]}
                >
                  <Input readOnly={isReadOnly} />
                </Form.Item>
                <Form.Item
                  label="Date Inquiry"
                  name="dateInquiry"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the date from!',
                    },
                  ]}
                >
                  <DatePicker
                    readOnly={isReadOnly}
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    style={{ width: '100%' }}
                    disabledDate={this.disabledDate}
                  />
                </Form.Item>
                <Form.Item
                  label="Lessor’s Email"
                  name="email"
                  rules={[{ required: true, message: 'Please input your email!' }]}
                >
                  <Input readOnly={isReadOnly} />
                </Form.Item>
                <Form.Item
                  label="Lessor’s Name"
                  name="name"
                  rules={[{ required: true, message: 'Please input your name!' }]}
                >
                  <Input readOnly={isReadOnly} />
                </Form.Item>
                <Form.Item
                  label="Lessor’s Contact Number"
                  name="contactNumber"
                  rules={[{ required: true, message: 'Please input your contact number!' }]}
                >
                  <Input readOnly={isReadOnly} />
                </Form.Item>
              </Col>
              <Col md={12} lg={12} sm={24} xs={24}>
                <Form.Item
                  label="Approval Date"
                  name="approvalDate"
                  rules={[{ required: false, message: 'Please input your approval date!' }]}
                >
                  <Input readOnly={isReadOnly} disabled />
                </Form.Item>

                <Form.Item
                  label="Approved By"
                  name="approvedBy"
                  rules={[{ required: false, message: 'Please input your approved by!' }]}
                >
                  <Input readOnly={isReadOnly} disabled />
                </Form.Item>

                <Form.Item
                  label="Remarks"
                  name="remarks"
                  rules={[{ required: false, message: 'Please input your remarks!' }]}
                >
                  <Input.TextArea rows={3} />
                </Form.Item>

                <Form.Item
                  label="Status"
                  name="status"
                  rules={[{ required: true, message: 'Please input your status!' }]}
                >
                  <Select style={{ width: '100%' }} >
                    <Option value={'APPROVAL'}>APPROVAL</Option>
                    {
                      this.access('inquiries', 'approve') &&
                      <Option value={'APPROVED'}>APPROVED</Option>
                    }
                    {/* {
                      this.access('inquiries', 'ongoing') &&
                      <Option value={'ONGOING'}>ONGOING</Option>
                    }
                    {
                      this.access('inquiries', 'cancel') &&
                      <Option value={'CANCELLED'}>CANCELLED</Option>
                    } */}
                    {
                      this.access('inquiries', 'complete') &&
                      <Option value={'COMPLETED'}>COMPLETED</Option>
                    }
                    {
                      this.access('inquiries', 'reject') &&
                      <Option value={'REJECTED'}>REJECTED</Option>
                    }
                  </Select>
                </Form.Item>

              </Col>
            </Row>
            <Row>
              <Col md={24} lg={24} sm={24} align={'right'}>
                <Form.Item >
                  {
                    this.access('inquiries', 'complete') &&
                    <Button style={{ marginTop: 8 }} type="primary" htmlType="submit" loading={this.props.updateInquiryLoading || this.state.isLoading}>
                      Submit
                    </Button>
                  }

                </Form.Item>
              </Col>
            </Row>

          </Form>

        </Spin>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return {
    updateInquiryLoading: state.admin.inquiries.updateInquiryLoading,
    tenantsData: state.admin.tenant.tenantsData,
    tenantsLoading: state.admin.tenant.tenantsLoading,
    adminData: state.admin.utilities.adminData
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onUpdateInquiry: (data) => dispatch(updateInquiry(data)),
    onLoadTenant: (data) => dispatch(loadTenant(data)),
    onLoadInquiryById: (data) => dispatch(loadInquiryById(data))
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Edit))

