import React from 'react'
import { Row, Col, Form, Modal, Button, Input, Spin, Divider } from 'antd'
import { getUser } from '../../../../../utilities/token'
import { connect } from 'react-redux'
import { submitIncident } from '../../actions'
import ImageUploader from './imageBulkUploader'
import VideoUploader from './videoUploader'
import SlidingPane from "react-sliding-pane";
import { upload } from '../../../../../utilities/filesController'

class Add extends React.Component {
  constructor() {
    super()
    this.state = {
      isLoading: false,
      fileImage: undefined,
      fileVideo: undefined,
      fileImageUploaded: false,
      fileVideoUploaded: false,
      fileImageList: [],
      fileVideoList: []
    }
  }

  getFileImage(e) {
    const list = e.map(data => {
      return { name: data.name, url: `images/${data.name}` }
    })
    this.setState({ fileImageList: list })
    this.setState({ fileImage: e })
  }

  getFileVideo(e) {
    const list = e.map(data => {
      return { name: data.name, url: `videos/${data.name}` }
    })
    this.setState({ fileVideoList: list })
    this.setState({ fileVideo: e })
  }

  uploadFileImage() {
    return new Promise((resolve, reject) => {
      if (this.state.fileImage) {
        this.state.fileImage.map((items, index) => {
          upload(items, items.name, 'images').then(data => {
            return resolve(true)
          }).catch(err => {
            return Modal.error({
              title: 'This is an error message for uploading images',
              content: err,
            });
          })
        })
      } else {
        return resolve(true)
      }

    })
  }

  uploadFileVideo() {
    return new Promise((resolve, reject) => {
      if (this.state.fileVideo) {
        upload(this.state.fileVideo[0], this.state.fileVideo[0].name, 'videos').then(data => {
          return resolve(true)
        }).catch(err => {
          return Modal.error({
            title: 'This is an error message in Soa Web Client',
            content: err,
          });
        })
      } else {
        return resolve(true)
      }

    })
  }

  handleSubmit(values) {
    this.setState({ isLoading: true })
    const submitData = {
      "tenantId": getUser().id,
      "title": values.title,
      "description": values.description,
      "images": this.state.fileImageList,
      "video": this.state.fileVideoList,
      "status": 1
    }

    Promise.all([
      this.uploadFileImage(),
      this.uploadFileVideo()
    ]).then((res) => {
      const isAllResultTrue = res.every(data => data === true)
      if (isAllResultTrue) {
        this.props.onSubmitIncident(submitData)
        this.setState({ isLoading: false })
      } else {
        Modal.error({
          title: 'Unable to submit incident report',
          content: 'ERROR',
        });
        this.setState({ isLoading: false })
      }
    })
  }

  getTypeEnum(type) {
    switch (type) {
      case 'Delivery':
        return 1
      case 'MoveIn':
        return 2
      case 'MoveOut':
        return 3
      default:
        return 1
    }
  }

  render() {
    return (

      <SlidingPane
        isOpen={this.props.visible}
        title="ADD INCIDENT REPORT"
        subtitle=""
        width={'50%'}
        onRequestClose={() => {
          this.props.onCancel()
        }}
      >
        <Spin size="large"
          spinning={false}
        >
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={(e) => this.handleSubmit(e)}
          // onFinishFailed={onFinishFailed}
          >
            <Row gutter={12}>
              <Col md={24} lg={24} sm={24} xs={24}>
                <Form.Item
                  label="Title"
                  name="title"
                  rules={[{ required: true, message: 'Please input your title!' }]}
                >
                  <Input className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Description"
                  name="description"
                  rules={[{ required: true, message: 'Please input your description!' }]}
                >
                  <Input.TextArea rows={3} className='primary-input' />
                </Form.Item>
                <Col lg={24} md={24} xs={24} sm={24}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h1>Upload Maximum of 3 (Three) Images</h1>
                  </div>
                  <ImageUploader
                    getFile={e => this.getFileImage(e)}
                  />
                  <span style={{ fontSize: 10, color: 'red' }}>Multiple file (at least 3 images)</span>
                </Col>

                <Divider />

                <Col lg={24} md={24} xs={24} sm={24}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h1>Upload 1(one) video of incident report</h1>
                  </div>
                  <VideoUploader
                    getFile={e => this.getFileVideo(e)}
                  />
                  <span style={{ fontSize: 10, color: 'red' }}>Single file Only</span>
                </Col>
              </Col>
            </Row>
            <Row>
              <Col md={24} lg={24} sm={24} align={'left'}>
                <Form.Item >
                  <Button className='primary-btn' style={{ marginTop: 50 }} type="primary" htmlType="submit"
                    loading={this.props.postLoading || this.state.isLoading}
                  >
                    Submit Incident Report
                  </Button>

                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </SlidingPane>
    )
  }
}

function mapStateToProps(state) {
  return {
    postLoading: state.client.services.postLoading
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmitIncident: (data) => dispatch(submitIncident(data)),
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Add))
