import React from 'react'
import { Row, Col, Form, Button, Input, Select, Collapse, Modal, Spin, DatePicker } from 'antd'
import { connect } from 'react-redux'
import { updateVisitor } from '../../actions'
import { getUser } from '../../../../../utilities/token'
import SlidingPane from "react-sliding-pane";
import moment from 'moment'
import ImageUploader from './imageBulkUploader'
import { ImagePresignedUrl } from '../../../../../utilities/renderer'
import { upload } from '../../../../../utilities/filesController'

const { Option } = Select;
const { Panel } = Collapse;
class Edit extends React.Component {
  constructor() {
    super()
    this.state = {
      isLoading: false,
      isLoading: false,
      fileImage: undefined,
      fileImageList: []
    }
  }

  getFileImage(e) {
    const list = e.map(data => {
      return { name: data.name, url: `images/${data.name}` }
    })
    this.setState({ fileImageList: list })
    this.setState({ fileImage: e })
  }

  getStatusLabel(status) {
    switch (status) {
      case 1:
        return 'APPROVAL'
      case 2:
        return 'APPROVED'
      case 3:
        return 'ONGOING'
      case 4:
        return 'COMPLETED'
      case 5:
        return 'CANCELLED'
      case 6:
        return 'REJECTED'
      default:
        break;
    }
  }

  handleUpdate(values) {
    this.setState({ isLoading: true })
    const selectedRowId = this.props.selectedRow.id

    const submitData = {
      "tenantId": getUser().id,
      "approvalDate": values.approvalDate,
      "visitorsName": values.visitorsName,
      "address": values.address,
      "dateOfVisit": values.dateOfVisit,
      "purposeOfVisit": values.purposeOfVisit,
      "email": values.email,
      "phoneNumber": values.phoneNumber,
      "birthDate": values.birthDate,
      "gender": values.gender,
      "images": this.state.fileImageList
    }

    Promise.all([
      this.uploadFileImage(),
    ]).then((res) => {
      const isAllResultTrue = res.every(data => data === true)
      if (isAllResultTrue) {
        this.props.onUpdateVisitor({ ...submitData, id: selectedRowId }).then(res => {
          this.setState({ isLoading: false })
        })
      } else {
        Modal.error({
          title: 'Unable to submit visitors',
          content: 'ERROR',
        });
        this.setState({ isLoading: false })
      }
    })
  }

  uploadFileImage() {
    return new Promise((resolve, reject) => {
      if (this.state.fileImage) {
        this.state.fileImage.map((items, index) => {
          upload(items, items.name, 'images').then(data => {
            return resolve(true)
          }).catch(err => {
            return Modal.error({
              title: 'This is an error message for uploading images',
              content: err,
            });
          })
        })
      } else {
        return resolve(true)
      }
    })
  }

  disabledDate(current) {
    return current && current < moment().startOf('day');
  }

  render() {
    const { selectedRow } = this.props
    const disabled = selectedRow && selectedRow.status !== 1 // Cant edit if the status is for approval = 1
    return (

      <SlidingPane
        isOpen={this.props.visible}
        title="EDIT VISITOR"
        subtitle=""
        width={'50%'}
        onRequestClose={() => {
          this.props.onCancel()
        }}
      >
        <Spin size="large" spinning={false}>

          <Form
            name="basic"
            initialValues={{
              ...selectedRow,
              dateOfVisit: moment(selectedRow && selectedRow.dateOfVisit),
              arrivalDate: moment(selectedRow && selectedRow.arrivalDate),
              birthDate: moment(selectedRow && selectedRow.birthDate),
              status: this.getStatusLabel(selectedRow && selectedRow.status),
              approvedBy: selectedRow && selectedRow.approver && selectedRow && selectedRow.approver.name || '',
              approvalDate: selectedRow && selectedRow.approvalDate && moment(selectedRow && selectedRow.approvalDate).format('LLL') || '',
              completedDate: selectedRow && selectedRow.completedDate && moment(selectedRow && selectedRow.completedDate).format('LLL') || '',
              verifiedBy: selectedRow && selectedRow.verifier && selectedRow && selectedRow.verifier.name || '',
            }}
            onFinish={(e) => this.handleUpdate(e)}
          // onFinishFailed={onFinishFailed}
          >
            <Row gutter={12}>
              <Col md={12} lg={12} sm={24} xs={24}>
                <Form.Item
                  label="Name of Visitor"
                  name="visitorsName"
                  rules={[{ required: true, message: 'Please input the name of visitor!' }]}
                >
                  <Input disabled={disabled} className='primary-input' />
                </Form.Item>
                <Form.Item
                  label="Address"
                  name="address"
                  rules={[{ required: true, message: 'Please input the address!' }]}
                >
                  <Input disabled={disabled} className='primary-input' />
                </Form.Item>
                <Form.Item
                  label="Phone Number"
                  name="phoneNumber"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the phone number!'
                    },
                    {
                      pattern: /^[\d]{11,11}$/,
                      message: "Phone number should be 11 digits",
                    },
                  ]}
                >
                  <Input maxLength={11} disabled={disabled} className='primary-input' />
                </Form.Item>
                <Form.Item
                  label="Date of Visit"
                  name="dateOfVisit"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the date of visit!',
                    },
                  ]}
                >
                  <DatePicker
                    className='primary-input'
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    style={{ width: '100%' }}
                    disabledDate={this.disabledDate}
                  />
                </Form.Item>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[{ required: true, message: 'Please input the email!' }]}
                >
                  <Input disabled={disabled} className='primary-input' />
                </Form.Item>

              </Col>

              <Col md={12} lg={12} sm={24} xs={24}>
                <Form.Item
                  label="Approval Date"
                  name="approvalDate"
                >
                  <Input readOnly disabled className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Approved By"
                  name="approvedBy"
                >
                  <Input readOnly disabled className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Verified By"
                  name="verifiedBy"
                >
                  <Input readOnly disabled className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Completed Date"
                  name="completedDate"
                >
                  <Input readOnly disabled className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Remarks"
                  name="remarks"
                >
                  <Input.TextArea rows={3} readOnly disabled className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Status"
                  name="status"
                  rules={[{ required: true, message: 'Please input your status!' }]}
                >
                  <Select readOnly disabled style={{ width: '100%' }} className='primary-input-select' >
                    <Option value={'APPROVAL'}>APPROVAL</Option>
                    <Option value={'APPROVED'}>APPROVED</Option>
                    <Option value={'ONGOING'}>ONGOING</Option>
                    <Option value={'COMPLETED'}>COMPLETED</Option>
                    <Option value={'CANCELLED'}>CANCELLED</Option>
                    <Option value={'REJECTED'}>REJECTED</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={24} lg={24} sm={24} xs={24}>
                <Collapse bordered={false} ghost>
                  <h1>Images</h1>
                  {
                    this.props.selectedRow && this.props.selectedRow.images && this.props.selectedRow.images.map((data, index) => {
                      return (
                        <Panel header={data.name} key={index}>
                          <ImagePresignedUrl style={{ width: 400, height: "auto" }} src={`${data.url}`} />
                        </Panel>
                      )
                    })
                  }
                </Collapse>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <h1>Upload scanned copy of one (1) valid government - issued id with photo.</h1>
                </div>
                <ImageUploader
                  getFile={e => this.getFileImage(e)}
                />
                <span style={{ fontSize: 10, color: 'red' }}>Single file</span>

              </Col>
            </Row>
            <Row>
              <Col md={24} lg={24} sm={24} align={'left'}>
                <Form.Item >
                  <Button className='primary-btn' style={{ marginTop: 50 }} disabled={disabled} type="primary" htmlType="submit" loading={this.props.updateVisitorLoading || this.state.isLoading}>
                    Update Visitor
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </SlidingPane>
    )
  }
}

function mapStateToProps(state) {
  return {
    updateVisitorLoading: state.client.visitors.updateVisitorLoading,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onUpdateVisitor: (data) => dispatch(updateVisitor(data)),
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Edit))

