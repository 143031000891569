import React from 'react'
import './index.scss'
import { Row, Col, Layout, Modal, Button, Input, Card, Form, Divider, Switch } from 'antd'
import { connect } from 'react-redux'
import { loadWebsiteSettings, submitWebsiteSettings, GET_WEBSITE_SETTINGS_FULFILLED } from '../actions'
import { upload } from '../../../../utilities/filesController'
import Upload from './upload'
class WebsiteSettings extends React.Component {
  constructor() {
    super()
    this.state = {
      fileImageLogo: [],
      fileLoginBackgroundImage: [],
      isLoading: false,
    }
  }
  formRef = React.createRef();

  componentDidMount() {
    this.props.onLoadWebsiteSettings().then(res => {
      const { payload } = res
      if (res.type === GET_WEBSITE_SETTINGS_FULFILLED) {
        const isUnderMaintenance = this.getWebsiteSettingsValue(payload, 'isUnderMaintenance') === '1'
        this.formRef.current.setFieldsValue({
          websiteName: this.getWebsiteSettingsValue(payload, 'websiteName'),
          isUnderMaintenance: isUnderMaintenance,
          maintenanceMessage: this.getWebsiteSettingsValue(payload, 'maintenanceMessage'),
        })
        this.setState({ isUnderMaintenance: isUnderMaintenance })
      }
    })
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.updateWebsiteSettingsSuccess !== this.props.updateWebsiteSettingsSuccess) && this.props.updateWebsiteSettingsSuccess) {
      Modal.success({
        content: 'Website settings successfully updated.',
      });
    }
  }

  async handleSubmit(values) {
    const arrayValues = []
    Object.keys(values).map(function (key, index) {
      arrayValues.push({
        name: key,
        value: values[key]
      })
    });

    const folder = 'logo'

    if (this.state.fileImageLogo.length !== 0) {
      await upload(this.state.fileImageLogo[0], this.state.fileImageLogo[0].name, 'logo').then(data => {
        arrayValues.push({
          name: 'websiteLogo',
          value: `${folder}/${this.state.fileImageLogo[0].name}`
        })
      })
    }

    if (this.state.fileLoginBackgroundImage.length !== 0) {
      await upload(this.state.fileLoginBackgroundImage[0], this.state.fileLoginBackgroundImage[0].name, 'logo').then(data => {
        arrayValues.push({
          name: 'websiteLoginBackgroundImage',
          value: `${folder}/${this.state.fileLoginBackgroundImage[0].name}`
        })
      })
    }

    console.log("arrayValues", arrayValues)
    this.props.onSubmitWebsiteSettings({
      settings: arrayValues
    })
  }

  getWebsiteSettingsValue(data, name) {
    const found = data.find(data => data.name === name)
    if (found) {
      return found.value
    }
  }

  getValueInWebsiteSettings(name) {
    const found = this.props.websiteSettingsData.find(data => data.name === name)
    if (found) return found.value
    return
  }

  render() {
    return (
      <Layout className='website-settings-container' >
        <Layout.Content>
          <h1>Website Settings</h1>
          <Card loading={this.props.websiteSettingsLoading}>
            <Form
              name="basic"
              ref={this.formRef}
              onFinish={(values) => this.handleSubmit(values)}
            // onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label="Website Name"
                name="websiteName"
                rules={[
                  {
                    required: true,
                    message: 'Please input your website name!',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Divider />

              <Row className='mt5'>
                <Col md={8} lg={8} sm={24} xs={24}>
                  <h1 style={{ fontWeight: 550 }}>Logo Image</h1>
                  <Upload value={`${this.getValueInWebsiteSettings('websiteLogo')}`} getFile={e => this.setState({ fileImageLogo: e })} width={200} height={200} />
                </Col>
              </Row>

              <Divider />

              <Row className='mt5'>
                <Col md={8} lg={8} sm={24} xs={24}>
                  <h1 style={{ fontWeight: 550 }}>Login Background Image</h1>
                  <Upload value={`${this.getValueInWebsiteSettings('websiteLoginBackgroundImage')}`} getFile={e => this.setState({ fileLoginBackgroundImage: e })} width={200} height={200} />
                </Col>
              </Row>

              <Divider />

              <Row className='mt5'>
                <Col lg={12} md={12} xs={24} sm={24} >
                  <p style={{ color: 'red' }}>Note: Setting 'Maintenance' to enabled will make the system unusable. Message will appear in front page.</p>
                  <div>
                    <Form.Item
                      label="Under Maintenance"
                      name="isUnderMaintenance"
                    >
                      <Switch checked={this.state.isUnderMaintenance} onChange={() => this.setState({ isUnderMaintenance: !this.state.isUnderMaintenance })} />
                    </Form.Item>
                  </div>
                  <Form.Item
                    label="Under Maintenance Message"
                    name="maintenanceMessage"
                    rules={[
                      {
                        required: this.state.isUnderMaintenance,
                        message: 'Please input your maintenance message!',
                      },
                    ]}
                  >
                    <Input.TextArea rows={3} width={600} disabled={!this.state.isUnderMaintenance} placeholder={`Ex: Sorry for the inconvenience but we're performing some maintenance at the moment.`} />
                  </Form.Item>
                </Col>

              </Row>

              <Row>
                <Col md={24} lg={24} xs={24} sm={24} align='right'>
                  <Form.Item  >
                    <Button loading={this.state.isLoading || this.props.updateWebsiteSettingsLoading} type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                </Col>
              </Row>

            </Form>
          </Card>
        </Layout.Content>
      </Layout>
    )
  }
}

function mapStateToProps(state) {
  return {
    websiteSettingsData: state.superadmin.websiteSettings.websiteSettingsData,
    websiteSettingsLoading: state.superadmin.websiteSettings.websiteSettingsLoading,
    updateWebsiteSettingsLoading: state.superadmin.websiteSettings.updateWebsiteSettingsLoading,
    updateWebsiteSettingsSuccess: state.superadmin.websiteSettings.updateWebsiteSettingsSuccess
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onLoadWebsiteSettings: (data) => dispatch(loadWebsiteSettings(data)),
    onSubmitWebsiteSettings: (data) => dispatch(submitWebsiteSettings(data)),
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(WebsiteSettings))
