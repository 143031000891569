import React from 'react'
import { Row, Col, Form, Button, Input, Spin, DatePicker } from 'antd'
import { getUser } from '../../../../../utilities/token'
import { connect } from 'react-redux'
import { submitRentalInquiries } from '../../actions'
import moment from 'moment'
import SlidingPane from "react-sliding-pane";
class Add extends React.Component {
  constructor() {
    super()
    this.state = {
      isLoading: false
    }
  }

  handleSubmit(values) {
    this.setState({ isLoading: true })
    const submitData = {
      "tenantId": getUser().id,
      "name": values.name,
      "email": values.email,
      "title": values.title,
      "contactNumber": values.contactNumber,
      "title": values.title,
      "description": values.description,
      "dateInquiry": values.dateInquiry,
    }

    this.props.onSubmitRentalInquiries(submitData).then(res => {
      this.setState({ isLoading: false })
    })
  }

  disabledDate(current) {
    return current && current < moment().startOf('day');
  }

  render() {
    return (
      <SlidingPane
        isOpen={this.props.visible}
        title="ADD LEASING ASSISTANCE"
        subtitle=""
        width={'50%'}
        onRequestClose={() => {
          this.props.onCancel()
        }}
      >
        <Spin size="large"
          spinning={false}
        >
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={(e) => this.handleSubmit(e)}
          // onFinishFailed={onFinishFailed}
          >
            <Row gutter={12}>
              <Col md={24} lg={24} sm={24} xs={24}>
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[{ required: true, message: 'Please input your name!' }]}
                >
                  <Input className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Email"
                  name="email"
                  rules={[{ required: true, message: 'Please input your email!' }]}
                >
                  <Input className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Contact Number"
                  name="contactNumber"
                  rules={[{ required: true, message: 'Please input your contact number!' }]}
                >
                  <Input className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Title"
                  name="title"
                  rules={[{ required: true, message: 'Please input your title!' }]}
                >
                  <Input className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Description"
                  name="description"
                  rules={[{ required: true, message: 'Please input your description!' }]}
                >
                  <Input.TextArea rows={3} className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Date Inquiry"
                  name="dateInquiry"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the date inquiry!',
                    },
                  ]}
                >
                  <DatePicker
                    className='primary-input'
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    style={{ width: '100%' }}
                    disabledDate={this.disabledDate}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={24} lg={24} sm={24} align={'left'}>
                <Form.Item >
                  <Button className='primary-btn' style={{ marginTop: 50 }} type="primary" htmlType="submit"
                    loading={this.props.postLoading || this.state.isLoading}
                  >
                    Submit Leasing Assistance
                  </Button>

                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </SlidingPane >
    )
  }
}

function mapStateToProps(state) {
  return {
    postLoading: state.client.rentalInquiries.postLoading
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmitRentalInquiries: (data) => dispatch(submitRentalInquiries(data)),
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Add))
