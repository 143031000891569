import React from 'react'
import { Row, Col, Form, Modal, Button, Input, Select, Spin, Card, DatePicker, Space } from 'antd'
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux'
import { getUser } from '../../../../../utilities/token'
import { updateService } from '../../actions'
import { loadTenant } from '../../../tenants/actions'
import moment from 'moment'
const { Option } = Select;
class Add extends React.Component {
  constructor() {
    super()
    this.state = {
      base64ImageUrl: '',
      isLoading: false,
      fileImage: undefined,
      fileVideo: undefined,
      fileImageUploaded: false,
      fileVideoUploaded: false,
      fileImageList: [],
      fileVideoList: []
    }
  }

  getFileImage(e) {
    const list = e.map(data => {
      return { name: data.name, url: `images/${data.name}` }
    })
    this.setState({ fileImageList: list })
    this.setState({ fileImage: e })
  }

  getFileVideo(e) {
    const list = e.map(data => {
      return { name: data.name, url: `videos/${data.name}` }
    })
    this.setState({ fileVideoList: list })
    this.setState({ fileVideo: e })
  }

  componentDidMount() {
    this.setState({ base64ImageUrl: '' })
    this.props.onLoadTenant()
  }

  handleUpdate(values) {
    this.setState({ isLoading: true })
    const selectedRowId = this.props.selectedRow && this.props.selectedRow.id

    const submitData = {
      "dateApplied": values.dateApplied,
      "workServiceFor": values.workServiceFor,
      "workArea": values.workArea,
      "dateFrom": values.dateFrom,
      "dateTo": values.dateTo,
      "purpose": values.purpose,
      "contractors": values.contractors,
      "bondAmount": values.bondAmount,
      "remarks": values.remarks,
      "status": this.getStatusEnum(values.status),

      // APPROVED
      "approvalDate": (values.status === 'APPROVED' && (this.props.selectedRow.status !== this.getStatusEnum(values.status))) && moment(new Date()),
      "approvedById": (values.status === 'APPROVED' && (this.props.selectedRow.status !== this.getStatusEnum(values.status))) && getUser().id,


      // COMPLETED
      "dateCompleted": (values.status === 'COMPLETED' && (this.props.selectedRow.status !== this.getStatusEnum(values.status))) && moment(new Date()),

      // ONGOING
      "dateApplied": (values.status === 'ONGOING' && (this.props.selectedRow.status !== this.getStatusEnum(values.status))) && moment(new Date())

    }
    this.props.onUpdateService({ ...submitData, id: selectedRowId }).then(res => {
      this.setState({ isLoading: false })
    })

  }

  getStatusEnum(status) {
    switch (status) {
      case 'APPROVAL':
        return 1
      case 'APPROVED':
        return 2
      case 'ONGOING':
        return 3
      case 'COMPLETED':
        return 4
      case 'CANCELLED':
        return 5
      case 'REJECTED':
        return 6
      default:
        break;
    }
  }

  getStatusLabel(status) {
    switch (status) {
      case 1:
        return 'APPROVAL'
      case 2:
        return 'APPROVED'
      case 3:
        return 'ONGOING'
      case 4:
        return 'COMPLETED'
      case 5:
        return 'CANCELLED'
      case 6:
        return 'REJECTED'
      default:
        break;
    }
  }

  render() {
    const { selectedRow } = this.props
    const disabled = this.props.selectedRow.status !== 1 // Cant edit if the status is for approval = 1
    return (
      <Modal
        title="EDIT PERMIT"
        centered
        visible={this.props.visible}
        // onOk={() => setVisible(false)}
        onCancel={() => this.props.onCancel()}
        width={1000}
        footer={null}
      >
        <Spin size="large" spinning={this.props.tenantsLoading}>

          <Form
            name="basic"
            initialValues={{
              ...this.props.selectedRow,
              dateFrom: moment(this.props.selectedRow.dateFrom),
              dateTo: moment(this.props.selectedRow.dateTo),
              dateApplied: moment(this.props.selectedRow.dateApplied),
              dateCompleted: moment(this.props.selectedRow.dateCompleted),
              approvalDate: moment(this.props.selectedRow.approvalDate),
              status: this.getStatusLabel(this.props.selectedRow.status)
            }}
            onFinish={(e) => this.handleUpdate(e)}
          // onFinishFailed={onFinishFailed}
          >
            <Row gutter={12}>
              <Col md={12} lg={12} sm={24} xs={24}>
                <Form.Item
                  label="Work Service For"
                  name="workServiceFor"
                  rules={[{ required: true, message: 'Please input your work service for!' }]}
                >
                  <Input disabled={disabled} />
                </Form.Item>

                <Form.Item
                  label="Work Area"
                  name="workArea"
                  rules={[{ required: true, message: 'Please input your work area!' }]}
                >
                  <Input disabled={disabled} />
                </Form.Item>
                <Form.Item
                  label="Purpose"
                  name="purpose"
                  rules={[{ required: true, message: 'Please input your purpose!' }]}
                >
                  <Input disabled={disabled} />
                </Form.Item>
                <Form.Item
                  label="Covered From"
                  name="dateFrom"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the date from!',
                    },
                  ]}
                >
                  <DatePicker
                    disabled={disabled}
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
                <Form.Item
                  label="Covered To"
                  name="dateTo"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the date to!',
                    },
                  ]}
                >
                  <DatePicker
                    disabled={disabled}
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
                <Card title="List of Workers">
                  <Form.List name="contractors">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(field => (
                          <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                            <Form.Item
                              {...field}
                              name={[field.name, 'name']}
                              fieldKey={[field.fieldKey, 'name']}
                              rules={[{ required: true, message: 'Missing Name' }]}
                            >
                              <Input placeholder="Worker Name" disabled={disabled} />
                            </Form.Item>
                            <Form.Item
                              {...field}
                              name={[field.name, 'information']}
                              fieldKey={[field.fieldKey, 'information']}
                              rules={[{ required: true, message: 'Missing Information' }]}
                            >
                              <Input placeholder="Worker Information" disabled={disabled} />
                            </Form.Item>
                            <MinusCircleOutlined onClick={() => remove(field.name)} />
                          </Space>
                        ))}
                        <Form.Item>
                          <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                            Add Worker
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </Card>
              </Col>
              <Col md={12} lg={12} sm={24} xs={24}>

                <Form.Item
                  label="Date Applied"
                  name="dateApplied"
                  rules={[{ required: false, message: 'Please input your date applied!' }]}
                >
                  <Input disabled={disabled} disabled />
                </Form.Item>

                <Form.Item
                  label="Date Completed"
                  name="dateCompleted"
                  rules={[{ required: false, message: 'Please input your date completed!' }]}
                >
                  <Input disabled={disabled} disabled />
                </Form.Item>

                <Form.Item
                  label="Approval Date"
                  name="approvalDate"
                  rules={[{ required: false, message: 'Please input your approval date!' }]}
                >
                  <Input disabled={disabled} disabled />
                </Form.Item>

                <Form.Item
                  label="Approved By"
                  name="approvedById"
                  rules={[{ required: false, message: 'Please input your approved by!' }]}
                >
                  <Input disabled={disabled} disabled />
                </Form.Item>

                <Form.Item
                  label="Bond Amount"
                  name="bondAmount"
                  rules={[{ required: false, message: 'Please input your bond amount!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Remarks"
                  name="remarks"
                  rules={[{ required: false, message: 'Please input your remarks!' }]}
                >
                  <Input.TextArea rows={3} />
                </Form.Item>

                <Form.Item
                  label="Status"
                  name="status"
                  rules={[{ required: true, message: 'Please input your status!' }]}
                >
                  <Select style={{ width: '100%' }} >
                    {/* <Option value="">None</Option> */}
                    <Option value={'APPROVAL'}>APPROVAL</Option>
                    <Option value={'APPROVED'}>APPROVED</Option>
                    <Option value={'ONGOING'}>ONGOING</Option>
                    <Option value={'COMPLETED'}>COMPLETED</Option>
                    <Option value={'CANCELLED'}>CANCELLED</Option>
                    <Option value={'REJECTED'}>REJECTED</Option>
                  </Select>
                </Form.Item>

              </Col>
            </Row>
            <Row>
              <Col md={24} lg={24} sm={24} align={'right'}>
                <Form.Item >
                  <Button style={{ marginTop: 8 }} type="primary" htmlType="submit" loading={this.props.updateServiceLoading || this.state.isLoading}>
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>

          </Form>

        </Spin>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return {
    updateServiceLoading: state.admin.services.updateServiceLoading,
    tenantsData: state.admin.tenant.tenantsData,
    tenantsLoading: state.admin.tenant.tenantsLoading,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onUpdateService: (data) => dispatch(updateService(data)),
    onLoadTenant: (data) => dispatch(loadTenant(data))
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Add))



async function postData(url = '', data = {}) {
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    headers: {
      'Authorization': 'Client-ID 6ca8e4184363ab4',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ ...data })
  });
  return response.json();
}