
import React from 'react'
import { Row, Col, Layout, Card, Form, Button, Input, Modal } from 'antd'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { loadTenantData, updateTenant, GET_TENANT_INFO_FULFILLED } from '../actions'
import Upload from './upload'
import './index.scss'
import { getUser } from '../../../../utilities/token'
import { upload } from '../../../../utilities/filesController'
import { v4 as uuidv4 } from 'uuid';

class Profile extends React.Component {
    constructor() {
        super()
        this.state = {
            imageFile: undefined,
            isLoading: false
        }
    }
    formRef = React.createRef();
    componentDidMount() {
        const tenantId = getUser().id
        this.props.onLoadTenantData(tenantId).then(res => {
            const { payload } = res
            if (res.type === GET_TENANT_INFO_FULFILLED) {
                this.formRef.current.setFieldsValue({
                    email: payload.email,
                    firstName: payload.firstName,
                    image: payload.image,
                    lastName: payload.lastName,
                    middleName: payload.middleName,
                    phoneNumber: payload.phoneNumber,
                    unitNumber: payload.unitNumber,
                    address: payload.address,
                    socialMediaAccount: payload.socialMediaAccount,
                    type: 0,
                    ...payload
                })
            }
        })
    }

    componentDidUpdate(prevProps) {
        if ((this.props.updateTenantSuccess !== prevProps.updateTenantSuccess) && this.props.updateTenantSuccess) {
            Modal.success({
                title: 'Success',
                content: 'Profile Successfully updated.',
            });
        }
    }

    handleUpdate(values) {
        const id = uuidv4()
        const profileName = `profile_${id}`
        const tenantId = getUser().id
        const submitData = {
            id: tenantId,
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            middleName: values.middleName,
            phoneNumber: values.phoneNumber,
            unitNumber: values.unitNumber,
            address: values.address,
            socialMediaAccount: values.socialMediaAccount,
            type: 0,
        }
        if (this.state.imageFile === undefined) {
            this.props.onUpdateTenant({ ...submitData })
        } else {
            this.setState({ isLoading: true })
            upload(this.state.imageFile, profileName, 'images').then(data => {
                this.setState({ isLoading: false })
                this.props.onUpdateTenant({ ...submitData, image: data.key })
            }).catch(err => {
                this.setState({ isLoading: false })
            })
        }
    }

    render() {
        const { tenantInfoData } = this.props
        return (
            <Card className='mb5'>
                <Layout.Content>
                    <Row gutter={32}>
                        <Col md={6} lg={6} sm={24} xs={24} >
                            <Upload
                                value={tenantInfoData.image}
                                getFile={e => this.setState({ imageFile: e })}
                            />
                        </Col>
                        <Col md={18} lg={18} sm={24} xs={24} >
                            <Form
                                name="basic"
                                ref={this.formRef}
                                onFinish={(e) => this.handleUpdate(e)}
                            >
                                {/* <Row gutter={32}>
                                    <Col lg={8} md={8} sm={24} xs={24}>
                                        <Form.Item
                                            label="First Name"
                                            name="firstName"
                                            rules={[{ required: true, message: 'Please input your first name!' }]}
                                        >
                                            <Input className='primary-input' />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={8} md={8} sm={24} xs={24}>
                                        <Form.Item
                                            label="Middle Name"
                                            name="middleName"
                                            rules={[{ required: true, message: 'Please input your middle name!' }]}
                                        >
                                            <Input className='primary-input' />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={8} md={8} sm={24} xs={24}>
                                        <Form.Item
                                            label="Last Name"
                                            name="lastName"
                                            rules={[{ required: true, message: 'Please input your last name!' }]}
                                        >
                                            <Input className='primary-input' />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={32}>
                                    <Col lg={8} md={8} sm={24} xs={24}>
                                        <Form.Item
                                            label="Email"
                                            name="email"
                                            rules={[{ required: true, message: 'Please input your email!' }]}
                                        >
                                            <Input className='primary-input' />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={8} md={8} sm={24} xs={24}>
                                        <Form.Item
                                            label="Phone Number"
                                            name="phoneNumber"
                                            rules={[{ required: true, message: 'Please input your phone number!' }]}
                                        >
                                            <Input className='primary-input' />
                                        </Form.Item>

                                    </Col>
                                    <Col lg={8} md={8} sm={24} xs={24}>
                                        <Form.Item
                                            label="Type"
                                            name="type"
                                        >
                                            <Input className='primary-input' />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={32}>
                                    <Col lg={8} md={8} sm={24} xs={24}>
                                        <Form.Item
                                            label="Unit #"
                                            name="unitNumber"
                                            rules={[{ required: true, message: 'Please input your unit number!' }]}
                                        >
                                            <Input.TextArea rows={3} className='primary-input' />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={8} md={8} sm={24} xs={24}>
                                        <Form.Item
                                            label="Address"
                                            name="address"
                                            rules={[{ required: true, message: 'Please input your address!' }]}
                                        >
                                            <Input.TextArea rows={3} className='primary-input' />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={8} md={8} sm={24} xs={24}>
                                        <Form.Item
                                            label="Social Media Account"
                                            name="socialMediaAccount"
                                            rules={[{ required: true, message: 'Please input your social media account!' }]}
                                        >
                                            <Input className='primary-input' />
                                        </Form.Item>
                                    </Col>
                                </Row> */}

                                <Row gutter={32}>
                                    <Col lg={12} md={12} sm={24} xs={24}>
                                        <Form.Item
                                            label="Client Name"
                                            name="uoccname"
                                            rules={[{ required: true, message: 'Please input your client name!' }]}
                                        >
                                            <Input className='primary-input' readOnly />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={12} md={12} sm={24} xs={24}>
                                        <Form.Item
                                            label="Contact Person"
                                            name="uoccpers"
                                            rules={[{ required: true, message: 'Please input your contact person!' }]}
                                        >
                                            <Input className='primary-input' readOnly />
                                        </Form.Item>
                                    </Col>

                                </Row>

                                <Row gutter={32}>
                                    <Col lg={12} md={12} sm={24} xs={24}>
                                        <Form.Item
                                            label='Addresses'
                                            name="ubaddln1"
                                        >
                                            <Input className='primary-input' readOnly placeholder='Address 1' />
                                        </Form.Item>
                                        <Form.Item
                                            name="ubaddln2"
                                        >
                                            <Input className='primary-input' readOnly placeholder='Address 2' />
                                        </Form.Item>
                                        <Form.Item
                                            name="ubaddln3"
                                        >
                                            <Input className='primary-input' readOnly placeholder='Address 3' />
                                        </Form.Item>
                                        <Form.Item
                                            name="ubaddln4"
                                        >
                                            <Input className='primary-input' readOnly placeholder='Address 4' />
                                        </Form.Item>
                                    </Col>

                                    <Col lg={12} md={12} sm={24} xs={24}>
                                        <Form.Item
                                            label='Email'
                                            name="email"
                                            rules={[{ required: false, message: 'Please input your email!' }]}
                                        >
                                            <Input className='primary-input' readOnly placeholder='Address 4' />
                                        </Form.Item>

                                        <Form.Item
                                            label="Social Media Account"
                                            name="socialMediaAccount"
                                            rules={[{ required: false, message: 'Please input your social media account!' }]}
                                        >
                                            <Input className='primary-input' />
                                        </Form.Item>
                                    </Col>

                                </Row>

                                <Row style={{ width: '100%' }} >
                                    <Col md={24} lg={24} sm={24} xs={24} align="right">
                                        <Form.Item>
                                            <Button loading={this.state.isLoading} type="primary" className='primary-btn' htmlType="submit">
                                                Update Information
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>


                </Layout.Content>
            </Card>
        )
    }
}

function mapStateToProps(state) {
    return {
        tenantInfoData: state.client.settings.tenantInfoData,
        updateTenantSuccess: state.client.settings.updateTenantSuccess
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadTenantData: (data) => dispatch(loadTenantData(data)),
        onUpdateTenant: (data) => dispatch(updateTenant(data)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile))
