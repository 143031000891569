import React from 'react'
import { Row, Col, Form, Modal, Button, Input, Select, Spin, Collapse, DatePicker } from 'antd'
import { connect } from 'react-redux'
import { getUser } from '../../../../../utilities/token'
import { updateVisitor, loadVisitorById } from '../../actions'
import { loadTenant } from '../../../tenants/actions'
import ImageUploader from './imageBulkUploader'
import moment from 'moment'
import { getParamFromUrl } from '../../../../../utilities/helper'
import { ImagePresignedUrl } from '../../../../../utilities/renderer'
import { upload } from '../../../../../utilities/filesController'

const { Option } = Select;
const { Panel } = Collapse;
class Edit extends React.Component {
  formRef = React.createRef();
  constructor() {
    super()
    this.state = {
      isLoading: false,
      fileImage: undefined,
      fileVideo: undefined,
      fileImageUploaded: false,
      fileVideoUploaded: false,
      fileImageList: [],
      fileVideoList: [],
      // preloaded data only works when redirected from services and if the modal is open
      preloadedData: {}
    }
  }

  componentWillMount() {
    if ((getParamFromUrl('modal') === 'true') && Object.keys(this.props.selectedRow).length === 0) {
      this.setState({ isEditModalOpen: true })
      this.props.onLoadVisitorById(getParamFromUrl('serviceId')).then(res => {
        this.formRef.current.setFieldsValue({
          ...res.payload,
          dateOfVisit: moment(res.payload.dateOfVisit),
          arrivalDate: moment(res.payload.arrivalDate),
          birthDate: moment(res.payload.birthDate),
          status: this.getStatusLabel(res.payload.status),
          approvalDate: res.payload.approvalDate && moment(res.payload.approvalDate).format('LLL') || '',
          completedDate: res.payload.completedDate && moment(res.payload.completedDate).format('LLL') || '',
        });
        this.setState({ preloadedData: res.payload })
      })
    }
  }


  getFileImage(e) {
    const list = e.map(data => {
      return { name: data.name, url: `images/${data.name}` }
    })
    this.setState({ fileImageList: list })
    this.setState({ fileImage: e })
  }

  getFileVideo(e) {
    const list = e.map(data => {
      return { name: data.name, url: `videos/${data.name}` }
    })
    this.setState({ fileVideoList: list })
    this.setState({ fileVideo: e })
  }

  uploadFileImage() {
    return new Promise((resolve, reject) => {
      if (this.state.fileImage) {
        this.state.fileImage.map((items, index) => {
          upload(items, items.name, 'images').then(data => {
            return resolve(true)
          }).catch(err => {
            return Modal.error({
              title: 'This is an error message for uploading images',
              content: err,
            });
          })
        })
      } else {
        return resolve(true)
      }
    })
  }

  getStatusLabel(status) {
    switch (status) {
      case 1:
        return 'APPROVAL'
      case 2:
        return 'APPROVED'
      case 3:
        return 'ONGOING'
      case 4:
        return 'COMPLETED'
      case 5:
        return 'CANCELLED'
      case 6:
        return 'REJECTED'
      default:
        break;
    }
  }

  getStatusEnum(status) {
    switch (status) {
      case 'APPROVAL':
        return 1
      case 'APPROVED':
        return 2
      case 'ONGOING':
        return 3
      case 'COMPLETED':
        return 4
      case 'CANCELLED':
        return 5
      case 'REJECTED':
        return 6
      default:
        break;
    }
  }

  handleUpdate(values) {
    this.setState({ isLoading: true })
    const selectedRowId = (this.props.selectedRow && this.props.selectedRow.id) || this.state.preloadedData.id

    const submitData = {
      "approvalDate": values.approvalDate,
      "visitorsName": values.visitorsName,
      "address": values.address,
      "dateOfVisit": values.dateOfVisit,
      "purposeOfVisit": values.purposeOfVisit,
      "email": values.email,
      "phoneNumber": values.phoneNumber,
      "birthDate": values.birthDate,
      "gender": values.gender,
      "images": this.state.fileImageList,
      "remarks": values.remarks,
      "status": this.getStatusEnum(values.status),
      // APPROVED
      "approvalDate": (values.status === 'APPROVED' && (this.props.selectedRow.status !== this.getStatusEnum(values.status))) && moment(new Date()),
      "approvedById": (values.status === 'APPROVED' && (this.props.selectedRow.status !== this.getStatusEnum(values.status))) && getUser().id,

      // COMPLETED
      "completedDate": (values.status === 'COMPLETED' && (this.props.selectedRow.status !== this.getStatusEnum(values.status))) && moment(new Date()),

    }

    Promise.all([
      this.uploadFileImage(),
    ]).then((res) => {
      const isAllResultTrue = res.every(data => data === true)
      if (isAllResultTrue) {
        this.props.onUpdateVisitor({ ...submitData, id: selectedRowId }).then(res => {
          this.setState({ isLoading: false })
        })
      } else {
        Modal.error({
          title: 'Unable to submit incident report',
          content: 'ERROR',
        });
        this.setState({ isLoading: false })
      }
    })
  }

  disabledDate(current) {
    return current && current < moment().startOf('day');
  }

  getStatusEnum(status) {
    switch (status) {
      case 'APPROVAL':
        return 1
      case 'APPROVED':
        return 2
      case 'ONGOING':
        return 3
      case 'COMPLETED':
        return 4
      case 'CANCELLED':
        return 5
      case 'REJECTED':
        return 6
      default:
        break;
    }
  }

  access(title, role) {
    if (this.props.adminData.roles.length === 0) return false
    const found = this.props.adminData.roles.find(data => data.title === title)
    if (found !== -1) return (found.roles[role]) ? true : false
    else return false
  }

  render() {
    const { selectedRow } = this.props
    return (
      <Modal
        title="EDIT VISITOR"
        centered
        visible={this.props.visible}
        // onOk={() => setVisible(false)}
        onCancel={() => this.props.onCancel()}
        width={1000}
        footer={null}
      >
        <Spin size="large" spinning={this.props.tenantsLoading}>

          <Form
            name="basic"
            ref={this.formRef}
            initialValues={{
              ...this.props.selectedRow,
              dateOfVisit: moment(this.props.selectedRow.dateOfVisit),
              arrivalDate: moment(this.props.selectedRow.arrivalDate),
              birthDate: moment(this.props.selectedRow.birthDate),
              status: this.getStatusLabel(this.props.selectedRow.status),

              approvedBy: selectedRow.approver && selectedRow.approver.name || '',
              approvalDate: selectedRow.approvalDate && moment(selectedRow.approvalDate).format('LLL') || '',
              completedDate: selectedRow.completedDate && moment(selectedRow.completedDate).format('LLL') || '',
              verifiedBy: selectedRow.verifier && selectedRow.verifier.name || '',
            }}
            onFinish={(e) => this.handleUpdate(e)}
          // onFinishFailed={onFinishFailed}
          >
            <Row gutter={12}>
              <Col md={12} lg={12} sm={24} xs={24}>
                <Form.Item
                  label="Name of Visitor"
                  name="visitorsName"
                  rules={[{ required: true, message: 'Please input the name of visitor!' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Address"
                  name="address"
                  rules={[{ required: true, message: 'Please input the address!' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Gender"
                  name="gender"
                // rules={[{ required: true, message: 'Please input your type!' }]}
                >
                  <Select defaultValue='male'>
                    <Select.Option value={'male'}>Male</Select.Option>
                    <Select.Option value={'female'}>Female</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Phone Number"
                  name="phoneNumber"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the phone number!'
                    },
                    {
                      pattern: /^[\d]{11,11}$/,
                      message: "Phone number should be 11 digits",
                    },
                  ]}
                >
                  <Input maxLength={11} placeholder='Ex: 09854411254' />
                </Form.Item>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[{ required: true, message: 'Please input the email!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Date of Visit"
                  name="dateOfVisit"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the date of visit!',
                    },
                  ]}
                >
                  <DatePicker
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    style={{ width: '100%' }}
                    disabledDate={this.disabledDate}
                  />
                </Form.Item>
                <Form.Item
                  label="Purpose of Visit"
                  name="purposeOfVisit"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the purpose of visit!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col md={12} lg={12} sm={24} xs={24}>
                <Collapse bordered={false} ghost>
                  <h1>Images</h1>
                  {
                    this.props.selectedRow && this.props.selectedRow.images && this.props.selectedRow.images.map((data, index) => {
                      return (
                        <Panel header={data.name} key={index}>
                          <ImagePresignedUrl style={{ width: 400, height: "auto" }} src={`${data.url}`} />
                        </Panel>
                      )
                    })
                  }
                </Collapse>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <h1>Upload scanned copy of one (1) valid government - issued id with photo</h1>
                </div>
                <ImageUploader
                  getFile={e => this.getFileImage(e)}
                />
                <span style={{ fontSize: 10, color: 'red' }}>Single file</span>


                <Form.Item
                  label="Approval Date"
                  name="approvalDate"
                >
                  <Input readOnly disabled />
                </Form.Item>

                <Form.Item
                  label="Approved By"
                  name="approvedBy"
                >
                  <Input readOnly disabled />
                </Form.Item>

                <Form.Item
                  label="Verified By"
                  name="verifiedBy"
                >
                  <Input readOnly disabled />
                </Form.Item>

                <Form.Item
                  label="Completed Date"
                  name="completedDate"
                >
                  <Input readOnly disabled />
                </Form.Item>

                <Form.Item
                  label="Remarks"
                  name="remarks"
                >
                  <Input.TextArea rows={3} />
                </Form.Item>

                <Form.Item
                  label="Status"
                  name="status"
                  rules={[{ required: true, message: 'Please input your status!' }]}
                >
                  <Select style={{ width: '100%' }} >
                    <Option value={'APPROVAL'}>APPROVAL</Option>
                    {
                      this.access('visitors', 'approve') &&
                      <Option value={'APPROVED'}>APPROVED</Option>
                    }
                    {
                      // this.access('visitors', 'ongoing') &&
                      // <Option value={'ONGOING'}>ONGOING</Option>
                    }
                    {
                      this.access('visitors', 'complete') &&
                      <Option value={'COMPLETED'}>COMPLETED</Option>
                    }
                    {
                      this.access('visitors', 'cancel') &&
                      <Option value={'CANCELLED'}>CANCELLED</Option>
                    }
                    {
                      this.access('visitors', 'reject') &&
                      <Option value={'REJECTED'}>REJECTED</Option>
                    }
                  </Select>
                </Form.Item>

              </Col>
            </Row>
            <Row>
              <Col md={24} lg={24} sm={24} align={'right'}>
                <Form.Item >
                  {
                    this.access('visitors', 'edit') &&
                    <Button style={{ marginTop: 8 }} type="primary" htmlType="submit" loading={this.props.updateVisitorLoading || this.state.isLoading}>
                      Submit
                    </Button>
                  }
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return {
    updateVisitorLoading: state.admin.visitors.updateVisitorLoading,
    tenantsData: state.admin.tenant.tenantsData,
    tenantsLoading: state.admin.tenant.tenantsLoading,
    adminData: state.admin.utilities.adminData
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onUpdateVisitor: (data) => dispatch(updateVisitor(data)),
    onLoadTenant: (data) => dispatch(loadTenant(data)),
    onLoadVisitorById: (data) => dispatch(loadVisitorById(data))


  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Edit))

