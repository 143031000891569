import React from 'react'
import { Row, Col, Form, Button, Input, Select, Spin } from 'antd'
import { connect } from 'react-redux'
import { updateCommendation } from '../../actions'
import { getUser } from '../../../../../utilities/token'
import SlidingPane from "react-sliding-pane";
const { Option } = Select;

class Add extends React.Component {
  constructor() {
    super()
    this.state = {
      isLoading: false,
    }
  }

  getStatusLabel(status) {
    switch (status) {
      case 1:
        return 'APPROVAL'
      case 2:
        return 'APPROVED'
      case 3:
        return 'ONGOING'
      case 4:
        return 'COMPLETED'
      case 5:
        return 'CANCELLED'
      case 6:
        return 'REJECTED'
      default:
        break;
    }
  }

  handleUpdate(values) {
    this.setState({ isLoading: true })
    const selectedRowId = this.props.selectedRow && this.props.selectedRow.id

    const submitData = {
      "tenantId": getUser().id,
      "title": values.title,
      "comment": values.comment,
      "type": values.type,
    }
    this.props.onUpdateCommendation({ ...submitData, id: selectedRowId }).then(res => {
      this.setState({ isLoading: false })
    })
  }

  render() {
    const { selectedRow } = this.props
    const disabled = selectedRow && selectedRow.status !== 1 // Cant edit if the status is for approval = 1
    return (
      <SlidingPane
        isOpen={this.props.visible}
        title="EDIT COMMENDATION / TESTIMONIAL"
        subtitle=""
        width={'50%'}
        onRequestClose={() => {
          this.props.onCancel()
        }}
      >
        <Spin size="large" spinning={false}>

          <Form
            name="basic"
            initialValues={{
              ...selectedRow,
              status: this.getStatusLabel(selectedRow && selectedRow.status),
              approvedBy: selectedRow && selectedRow.approver && selectedRow && selectedRow.approver.name || '',
            }}
            onFinish={(e) => this.handleUpdate(e)}
          // onFinishFailed={onFinishFailed}
          >
            <Row gutter={12}>
              <Col md={12} lg={12} sm={24} xs={24}>
                <Form.Item
                  label="Title"
                  name="title"
                  rules={[{ required: true, message: 'Please input your title!' }]}
                >
                  <Input disabled={disabled} className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Comment"
                  name="comment"
                  rules={[{ required: true, message: 'Please input your comment!' }]}
                >
                  <Input.TextArea rows={5} disabled={disabled} className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Type"
                  name="type"
                  rules={[{ required: true, message: 'Please input your type!' }]}
                >
                  <Select style={{ width: '100%' }} className='primary-input-select'>
                    <Option value={1}>COMMENDATION</Option>
                    <Option value={2}>TESTIMONIAL</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col md={12} lg={12} sm={24} xs={24}>
                <Form.Item
                  label="Approval Date"
                  name="approvalDate"
                  rules={[{ required: false, message: 'Please input your approval date!' }]}
                >
                  <Input disabled className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Approved By"
                  name="approvedBy"
                  rules={[{ required: false, message: 'Please input your approved by!' }]}
                >
                  <Input disabled className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Remarks"
                  name="remarks"
                  rules={[{ required: false, message: 'Please input your remarks!' }]}
                >
                  <Input.TextArea rows={3} disabled className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Status"
                  name="status"
                  rules={[{ required: false, message: 'Please input your status!' }]}
                >
                  <Input disabled className='primary-input' />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={24} lg={24} sm={24} align={'left'}>
                <Form.Item >
                  <Button className='primary-btn' style={{ marginTop: 50 }} type="primary" htmlType="submit" loading={this.props.updateCommendationLoading || this.state.isLoading} disabled={disabled}>
                    Update Commendation / Testimonial
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </SlidingPane>
    )
  }
}

function mapStateToProps(state) {
  return {
    updateCommendationLoading: state.client.commendations.updateCommendationLoading,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onUpdateCommendation: (data) => dispatch(updateCommendation(data)),
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Add))

