import React from 'react'
import { Row, Col, Form, Modal, Button, Input, Select, Spin, Divider, Collapse } from 'antd'
import { connect } from 'react-redux'
import { updateIncident } from '../../actions'
import { getUser } from '../../../../../utilities/token'
import moment from 'moment'
import ImageUploader from './imageBulkUploader'
import VideoUploader from './videoUploader'
import { get, upload } from '../../../../../utilities/filesController'
import SlidingPane from "react-sliding-pane";
import { ImagePresignedUrl } from '../../../../../utilities/renderer'

const { Option } = Select;
const { Panel } = Collapse;

class Add extends React.Component {
  constructor() {
    super()
    this.state = {
      base64ImageUrl: '',
      isLoading: false,
      fileImage: undefined,
      fileVideo: undefined,
      fileImageUploaded: false,
      fileVideoUploaded: false,
      fileImageList: [],
      fileVideoList: []
    }
  }

  getFileImage(e) {
    const list = e.map(data => {
      return { name: data.name, url: `images/${data.name}` }
    })
    this.setState({ fileImageList: list })
    this.setState({ fileImage: e })
  }

  getFileVideo(e) {
    const list = e.map(data => {
      return { name: data.name, url: `videos/${data.name}` }
    })
    this.setState({ fileVideoList: list })
    this.setState({ fileVideo: e })
  }

  uploadFileImage() {
    return new Promise((resolve, reject) => {
      if (this.state.fileImage) {
        this.state.fileImage && this.state.fileImage.map((items, index) => {
          upload(items, items.name, 'images').then(data => {
            return resolve(true)
          }).catch(err => {
            return Modal.error({
              title: 'This is an error message for uploading images',
              content: err,
            });
          })
        })
      } else {
        return resolve(true)
      }
    })
  }

  uploadFileVideo() {
    return new Promise((resolve, reject) => {
      if (this.state.fileVideo) {
        upload(this.state.fileVideo[0], this.state.fileVideo[0].name, 'videos').then(data => {
          return resolve(true)
        }).catch(err => {
          return Modal.error({
            title: 'This is an error message in Soa Web Client',
            content: err,
          });
        })
      } else {
        return resolve(true)
      }
    })
  }

  componentDidMount() {
    this.setState({ base64ImageUrl: '' })
  }

  handleUpdate(values) {
    this.setState({ isLoading: true })
    const submitData = {
      "tenantId": getUser().id,
      "title": values.title,
      "description": values.description,
      "images": this.state.fileImageList,
      "video": this.state.fileVideoList,
    }
    const selectedRowId = this.props.selectedRow && this.props.selectedRow.id
    Promise.all([
      this.uploadFileImage(),
      this.uploadFileVideo()
    ]).then((res) => {
      const isAllResultTrue = res.every(data => data === true)
      if (isAllResultTrue) {
        this.props.onUpdateIncident({ ...submitData, id: selectedRowId })
        this.setState({ isLoading: false })
      } else {
        Modal.error({
          title: 'Unable to submit incident report',
          content: 'ERROR',
        });
        this.setState({ isLoading: false })
      }
    })
  }

  getStatusLabel(status) {
    switch (status) {
      case 1:
        return 'APPROVAL'
      case 2:
        return 'APPROVED'
      case 3:
        return 'ONGOING'
      case 4:
        return 'COMPLETED'
      case 5:
        return 'CANCELLED'
      case 6:
        return 'REJECTED'
      default:
        break;
    }
  }

  getTypeEnum(type) {
    switch (type) {
      case 'Delivery':
        return 1
      case 'MoveIn':
        return 2
      case 'MoveOut':
        return 3
      default:
        return 1
    }
  }

  async openUrl(pathname) {
    const url = await get(pathname)
    window.open(url);
  }


  render() {
    const { selectedRow } = this.props
    const disabled = selectedRow && selectedRow.status !== 1 // Cant edit if the status is for approval = 1
    return (

      <SlidingPane
        isOpen={this.props.visible}
        title="EDIT INCIDENT REPORT"
        subtitle=""
        width={'50%'}
        onRequestClose={() => {
          this.props.onCancel()
        }}
      >
        <Spin size="large" spinning={false}>

          <Form
            name="basic"
            initialValues={{
              ...selectedRow,
              dateOfApplication: moment(selectedRow && selectedRow.dateOfApplication),
              dateOfUse: moment(selectedRow && selectedRow.dateOfUse),
              status: this.getStatusLabel(selectedRow && selectedRow.status),
              approvedBy: selectedRow && selectedRow.approver && selectedRow && selectedRow.approver.name || '',
              approvalDate: selectedRow && selectedRow.approvalDate && moment(selectedRow && selectedRow.approvalDate).format('LLL') || '',
              completedDate: moment(selectedRow && selectedRow.completedDate).format('LLL') || '',
              caseClosedDate: moment(selectedRow && selectedRow.caseClosedDate).format('LLL') || '',
              reviewDate: moment(selectedRow && selectedRow.reviewDate).format('LLL') || '',
              verifiedBy: selectedRow && selectedRow.verifier && selectedRow && selectedRow.verifier.name || '',
            }}
            onFinish={(e) => this.handleUpdate(e)}
          // onFinishFailed={onFinishFailed}
          >
            <Row gutter={12}>
              <Col md={12} lg={12} sm={24} xs={24}>
                <Form.Item
                  label="Title"
                  name="title"
                  rules={[{ required: true, message: 'Please input your title!' }]}
                >
                  <Input disabled={disabled} className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Description"
                  name="description"
                  rules={[{ required: true, message: 'Please input your description!' }]}
                >
                  <Input.TextArea rows={3} disabled={disabled} className='primary-input' />
                </Form.Item>

              </Col>

              <Col md={12} lg={12} sm={24} xs={24}>

                <Form.Item
                  label="Approval Date"
                  name="approvalDate"
                >
                  <Input readOnly disabled className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Approved By"
                  name="approvedBy"
                >
                  <Input readOnly disabled className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Verified By"
                  name="verifiedBy"
                >
                  <Input readOnly disabled className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Review Date"
                  name="reviewDate"
                >
                  <Input readOnly disabled className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Completed Date"
                  name="completedDate"
                >
                  <Input readOnly disabled className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Case Closed Date"
                  name="caseClosedDate"
                >
                  <Input readOnly disabled className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Status"
                  name="status"
                  rules={[{ required: true, message: 'Please input your status!' }]}
                >
                  <Select readOnly disabled style={{ width: '100%' }} className='primary-input-select'>
                    <Option value={'APPROVAL'}>APPROVAL</Option>
                    <Option value={'APPROVED'}>APPROVED</Option>
                    <Option value={'ONGOING'}>ONGOING</Option>
                    <Option value={'COMPLETED'}>COMPLETED</Option>
                    <Option value={'CANCELLED'}>CANCELLED</Option>
                    <Option value={'REJECTED'}>REJECTED</Option>
                  </Select>
                </Form.Item>

              </Col>
            </Row>
            {
              selectedRow && selectedRow.caseClosedImages &&
              <>
                <h1>Case Closed</h1>
                <Row>
                  <Col md={24} lg={24} sm={24} xs={24}>
                    <div>
                      <span>Incident Images</span>
                      <Collapse bordered={false} ghost>
                        {
                          selectedRow && selectedRow.caseClosedImages && selectedRow.caseClosedImages.map((data, index) => {
                            return (
                              <Panel header={data.name} key={index}>
                                <ImagePresignedUrl style={{ width: 400, height: "auto" }} src={`${data.url}`} />
                              </Panel>
                            )
                          })
                        }
                      </Collapse>

                    </div>
                    <div>
                      <span>Incident Video</span>
                      <Collapse bordered={false} ghost>
                        {
                          selectedRow && selectedRow.caseClosedVideos && selectedRow.caseClosedVideos.map((data, index) => {
                            return (
                              <Panel header={data.name} key={index}>
                                <a onClick={() => { this.openUrl(data.url) }} >{data.name}</a>
                              </Panel>
                            )
                          })
                        }
                      </Collapse>
                    </div>
                  </Col>
                </Row>
                <Divider />
              </>
            }

            <Row>
              <Col lg={24} md={24} xs={24} sm={24}>
                <Collapse bordered={false} ghost>
                  <h1>Images</h1>
                  {
                    selectedRow && selectedRow.images && selectedRow.images.map((data, index) => {
                      return (
                        <Panel header={data.name} key={index}>
                          <ImagePresignedUrl style={{ width: 400, height: "auto" }} src={`${data.url}`} />
                        </Panel>
                      )
                    })
                  }
                </Collapse>
                <Collapse bordered={false} ghost>
                  <h1>Videos</h1>
                  {
                    selectedRow && selectedRow.video && selectedRow.video.map((data, index) => {
                      return (
                        <Panel header={data.name} key={index}>
                          <a onClick={() => { this.openUrl(data.url) }} >{data.name}</a>
                        </Panel>
                      )
                    })
                  }
                </Collapse>
              </Col>
            </Row>
            <Row>
              <Col lg={24} md={24} xs={24} sm={24}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <h1>Upload Maximum of 3 (Three) Images</h1>
                </div>
                <ImageUploader
                  value={selectedRow && selectedRow}
                  getFile={e => this.getFileImage(e)}
                />
                <span style={{ fontSize: 10, color: 'red' }}>Multiple file (at least 3 images)</span>
              </Col>

              <Divider />

              <Col lg={24} md={24} xs={24} sm={24}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <h1>Upload 1(one) video of incident report</h1>
                </div>
                <VideoUploader
                  value={selectedRow && selectedRow}
                  getFile={e => this.getFileVideo(e)}
                />
                <span style={{ fontSize: 10, color: 'red' }}>Single file Only</span>
              </Col>
            </Row>
            <Row>
              <Col md={24} lg={24} sm={24} align={'left'}>
                <Form.Item >
                  <Button className='primary-btn' disabled={disabled} style={{ marginTop: 50 }} type="primary" htmlType="submit" loading={this.props.updateIncidentLoading || this.state.isLoading}>
                    Update Incident Report
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </SlidingPane>
    )
  }
}

function mapStateToProps(state) {
  return {
    updateIncidentLoading: state.client.services.updateIncidentLoading,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onUpdateIncident: (data) => dispatch(updateIncident(data)),
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Add))

